<template>
  <div class="thanksPatronize" :class="{ 'load-bg': redStates }">
    <div
      class="ad ad-default"
      :class="{ 'ad-show': !redStates && openRedSuccess }"
    >
      <img
        class="advertisement"
        src="../../assets/redEnvelopes/banner.gif"
        alt=""
      />
      <wx-open-launch-weapp
        username="gh_cae1ebd40f5a"
        path="pages/common/blank-page/index?weappSharePath=pages%2Fhome%2Ffeature%2Findex%3Falias%3DTfXqgnSwg0%26kdt_id%3D96300117"
        class="launch-btn"
        @click="sendMaidian"
      >
        <script type="text/wxtag-template">
          <style>.weapp-btn {width: 100vw; height: 100vw;}</style>
          <div class="weapp-btn">&nbsp;</div>
        </script>
      </wx-open-launch-weapp>
    </div>
    <div
      v-if="redStates"
      class="redEnvelopesBg"
      :class="{ 'load-bg': redStates }"
    >
      <img src="../../assets/redEnvelopes/bgz.png" alt="" />
      <div class="redEnvelopesBox">
        <p class="redEnvelopesTips" v-html="tips"></p>
        <div class="redEnvelopesBorder" v-show="openRed">
          <img src="../../assets/redEnvelopes/red.png" alt="" />
          <div class="redEnvelopesOpen" @click="openAxjx()">
            <img src="../../assets/redEnvelopes/kai.png" alt="" />
          </div>
        </div>
        <div class="redEnvelopesresult" v-show="openRedRepeat">
          <img src="../../assets/redEnvelopes/get-red.png" alt="" />
          <div class="edEnvelopesMoney">
            <p>您的红包已发放</p>
          </div>
        </div>
      </div>
    </div>

    <template v-else>
      <div v-if="openRedSuccess" class="success">
        <img
          class="bg"
          :src="require('../../assets/redEnvelopes/success-bg.png')"
          alt=""
        />
        <div class="content content-success">
          <div class="ad"></div>
          <div class="code-tip">
            <p>恭喜您获得红包，将于24小时之内到账</p>
            <p>请返回微信领取红包~</p>
          </div>
          <div class="tip">
            <p>恭喜您中得</p>
            <p class="money-box">
              <span class="border-font">{{ money }}</span>
              <span class="unit">元</span>
            </p>
          </div>
          <div class="bottom">
            <moreGift @drawAfter="drawAfter"></moreGift>
          </div>
        </div>
      </div>
      <div v-else class="fail">
        <img
          class="bg"
          :src="require('../../assets/redEnvelopes/fail-bg.png')"
          alt=""
        />
        <div class="content">
          <div class="tip">
            <p>{{ errorText }}</p>
            <!-- <p>下次继续努力</p> -->
            <!-- <p>祝您好运~</p> -->
          </div>
          <div class="bottom">
            <moreGift @drawAfter="drawAfter"></moreGift>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { receiveRedPacket ,journalApplet} from '@/api/index.js'
import { setSessionStorage } from '@/utils/storage'
import setting from '@/setting'
import moreGift from '@/components/moreGift/index.vue'

export default {
  name: 'redEnvelopes',
  components: {
    moreGift
  },
  data() {
    return {
      tips: '恭喜您获得红包一个',
      openRed: true,
      money: '00.00',
      redStates: true, // 准备开启红包
      isOpen: true, // 防止重复点击
      openRedRepeat: false, // 红包是否已经领取
      openRedSuccess: false, // 红包领取成功
      errorText: '',
      
      journalParams: {
        location: 3, // 3：红包领取成功页顶部
        advertiseTitle: "今麦郎私域引流", // 广告标题
        advertiseType: 1, //广告类型 1：图片 2：视频
        pictureUrl: "location", //图片地址
        videoUrl: "location", //视频地址
        linkType: 2, // 1:h5; 2：小程序
        originalId: "gh_cae1ebd40f5a", //"小程序原始id"
        linkUrl:
          "pages/common/blank-page/index?weappSharePath=pages%2Fhome%2Ffeature%2Findex%3Falias%3DTfXqgnSwg0%26kdt_id%3D96300117", //跳转链接
        operate: 1, // 操作：1：曝光 2：点击
      },
    }
  },
  methods: {
      // 点击小程序入口请求埋点
    sendMaidian() {
      journalApplet(Object.assign(this.journalParams, { operate: 2 })); // 专门针对小程序入口展示的埋点
    },
    openAxjx() {
      if (this.isOpen) {
        this.isOpen = false
        receiveRedPacket({
          prizeId: this.$route.query.prizeId,
          drawLogId: this.$route.query.drawLogId
        }).then(
          res => {
            if (res.data.status == 200) {
              this.openRed = false
              this.openRedSuccess = true
              this.money =
                setting.redEnvelopeApiType == 'DouMi'
                  ? res.data.unit_money
                  : res.data.data.unit_money
              this.tips =
                '恭喜您获得红包，将于24小时之内到账</br>请返回微信领取红包'
              this.redStates = false
               journalApplet(this.journalParams); // 专门针对小程序入口展示的埋点
            } else if (res.data.status == 400001) {
              this.openRed = false
              this.openRedRepeat = true
              // this.tips =
              //   "恭喜您获得红包，将于24小时之内到账</br>请返回微信领取红包";
            } else {
              this.redStates = false
              this.errorText = res.data.msg
            }
            this.isOpen = true // 防止重复点击
          },
          () => {
            this.isOpen = true
          }
        )
      }
    },
    returnBtn() {
      this.$router.go(-1)
    },
    drawAfter() {
      setSessionStorage('isMiddlePageDraw', true)
    }
  },
  mounted() {
    setSessionStorage('isMiddlePageEnter', true)
    this.openAxjx()
  },
  beforeRouteEnter(to, from, next) {
    // 不是从转盘页面来的就是从电子奖页面来的
    if (from.name != 'turntable') {
      next({
        path: '/codeUsed'
      })
    } else {
      // sessionStorage.setItem("backValue",5)
      next()
    }
  }
}
</script>

<style scoped lang="less">
.load-bg {
  background-color: #fff !important;
}
.thanksPatronize {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;

  .errorText {
    width: 430px;
    margin: 0 auto;
    font-size: 34px;
    text-align: left;
  }
  /* body {
  background: #c5000f;
} */
  .redEnvelopesBg {
    background: #fff;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .redEnvelopesBg img {
    display: block;
    width: 100%;
  }

  .redEnvelopesBox {
    position: absolute;
    top: 295px;
    left: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 750px;
  }

  .redEnvelopesTips {
    width: 692px;
    height: 133px;
    line-height: 133px;
    background: rgba(60, 178, 245, 0.3);
    text-align: center;
    font-size: 34px;
    color: #DA4A30;
    margin: 0 auto;
    border-radius: 5px;
  }

  .redEnvelopesBorder {
    position: relative;
    margin-top: 20px;
  }

  .redEnvelopesresult {
    width: 750px;
    margin-top: 20px;
  }

  .edEnvelopesMoney {
    position: absolute;
    top: 460px;
    left: 0;
    font-size: 40px;
    width: 100%;
    text-align: center;
    color: rgb(240, 218, 21);
  }
  .errorTips {
    width: 750px;
    margin-top: 100px;
  }
  .errorTips .edEnvelopesMoney {
    top: 420px;
  }
  .redEnvelopesOpen {
    position: absolute;
    top: 220px;
    width: 124px;
    left: 313px;
    animation: move 0.8s 0s infinite;
    -webkit-animation: move 0.8s 0s infinite;
    transform-origin: bottom;
    -webkit-transform-origin: bottom;
  }

  .edEnvelopesMoney span {
    font-size: 80px;
    color: red;
    text-shadow: #fff 2px 0 0, #fff 0 2px 0, #fff -2px 0 0, #fff 0 -2px 0;
  }

  .callBack {
    width: 417px;
    margin: 20px auto 0;
  }

  @keyframes move {
    0% {
      transform: scale(1.1);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.1);
    }
  }

  .success {
    width: 100%;
    .bg {
      width: 100%;
      display: block;
    }
    .content {
      width: 100%;
      position: absolute;
      top: 298px;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .code-tip {
        width: 692px;
        height: 99px;
        background: rgba(60, 178, 245, 0.3);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 230px;
        padding-top: 5px;
        p {
          font-size: 28px;
          font-weight: 400;
          color: #DA4A30;
          line-height: 40px;
        }
      }
      .tip {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 80px;

        p {
          font-size: 39px;
          font-weight: 400;
          color: #fff576;
          text-align: center;

          &.money-box {
            margin-top: 20px;
            padding-left: 30px;
            box-sizing: border-box;
          }
          .border-font {
            font-size: 87px;
            color: red;
            text-shadow: #fff 3px 0 0, #fff 0 3px 0, #fff -3px 0 0,
              #fff 0 -3px 0;
          }
          .unit {
            margin-left: 15px;
            font-size: 42px;
            color: #fff576;
          }
        }
      }
    }
    .content-success {
      left: 0;
      top: 0;
      padding-top: 39px;
    }
  }

  .fail {
    width: 100%;

    .bg {
      width: 100%;
      display: block;
    }
    .content {
      position: absolute;
      top: 680px;
      width: 100%;
      // background: yellow;
      .tip {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 280px;
        p {
          width: 360px;
          text-align: center;
          font-size: 36px;
          font-weight: 400;
          color: #FCEE98;
          line-height: 51px;
        }
      }
    }
  }
}
.ad {
  position: relative;
  width: 677px;
  height: 194px;
  overflow: hidden;
  margin-bottom: 140px;
  border-radius: 20px;
  border: 4px solid #fff;
  overflow: hidden;
  box-sizing: border-box;

  .advertisement {
    width: 677px;
    height: 194px;
    display: block;
    overflow: hidden;
    border-radius: 20px;
  }
  .launch-btn {
    position: absolute;
    left: 0;
    top: 0;
    width: 677px;
    height: 194px;
    z-index: 99;
  }
}

.ad-default {
  position: absolute;
  left: 50%;
  top: -1000px;
  transform: translateX(-50%);
  z-index: 200;
}
.ad-show {
  top: 39px;
}
</style>
